import React from 'react';
import Seo from '../components/Seo';
import Main from '../components/Main';
import Takeover from '../components/Takeover';
import ProjectCarousel from '../components/ProjectCarousel';
import IntroVideo from '../components/IntroVideo';

export default ({
	pageContext,
	location,
}) => {
	const {seo = {}} = pageContext;
	
	return (
		<React.Fragment>
			<Seo
				metaTitle="Not Found - Data Plus"
				openGraphTitle="Not Found - Data Plus"
				twitterTitle="Not Found - Data Plus"
				pathname={location.pathname}
			/>
			<p className="main__c">404</p>
		</React.Fragment>
	);
};